<template>
  <div>
    <validation-observer ref="issueForm">
      <b-form>
        <b-row>
          <b-col cols="8">
            <b-card>
              <b-row>
                <b-col md="6">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Issue Name"
                      rules="required"
                    >
                      <b-form-input
                        v-model="name"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Issue Name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <div class="media-selecion">
                    <div>
                      <MediaUploader
                        @mediaSelected="handleLogoSelect"
                        text="Select Logo"
                        :isMultiple="false"
                      />
                    </div>
                  </div>
                </b-col>
                <b-col cols="12">
                  <b-button
                    variant="primary"
                    type="submit"
                    @click.prevent="createIssue"
                  >
                    <b-spinner v-if="creatingIssue" small />
                    Create Issue
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col cols="4">
            <b-card v-if="icon">
              <div class="text-center">
                <b-img v-if="icon" fluid :src="icon"></b-img>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <b-card title="Issues">
      <b-card-header class="justify-content-end pr-0">
        <div>
          <b-form-input
            v-model="search"
            placeholder="Search Issue"
          ></b-form-input>
        </div>
        <div class="ml-1">
          <b-button
            @click="getIssues"
            variant="outline-primary"
            class="btn-icon"
          >
            <feather-icon icon="SearchIcon"></feather-icon>
          </b-button>
        </div>
      </b-card-header>
      <b-table
        :busy="loading"
        show-empty
        responsive
        :items="issues"
        :fields="issueFields"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1"></b-spinner>
            <strong>Loading data...</strong>
          </div>
        </template>
        <template #empty>
          <div class="text-center">
            <b-col cols="4" class="mx-auto my-2">
              <h4>No Data Found</h4>
              <feather-icon size="32" icon="AlertTriangleIcon"></feather-icon>
            </b-col>
          </div>
        </template>

        <template #cell(issue)="data">
          <b-avatar class="mr-1" :src="data.item.icon" />
          {{ data.item.name }}
        </template>
        <template #cell(action)="data">
          <b-button variant="outline-primary" class="btn-icon">
            <feather-icon icon="EditIcon"></feather-icon>
          </b-button>
        </template>
      </b-table>

      <b-card-footer>
        <b-pagination
          :per-page="perPage"
          v-model="page"
          :total-rows="rows"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </b-card-footer>
    </b-card>
  </div>
</template>

<script>
import {
  BContainer,
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
  BCard,
  BImg,
  BSpinner,
  BTable,
  BAvatar,
  BCardFooter,
  BPagination,
  BCardHeader,
} from "bootstrap-vue";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import MediaUploader from "../../components/MediaUploader.vue";
import { getUserToken } from "@/auth/utils";
import axios from "axios";

export default {
  components: {
    BContainer,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    BCard,
    MediaUploader,
    BImg,
    BSpinner,
    BTable,
    BAvatar,
    BCardFooter,
    BPagination,
    BCardHeader,
  },
  data() {
    return {
      name: "",
      icon: "",
      required,
      creatingIssue: false,
      issueFields: ["issue", "action"],
      issues: [],
      loading: true,
      perPage: 25,
      page: 1,
      rows: 0,
      search: "",
    };
  },
  methods: {
    delay(delay) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve();
        }, delay);
      });
    },
    makeToast(variant, title, content) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
    createIssue() {
      this.$refs.issueForm.validate().then((success) => {
        if (success) {
          let icon = this.icon;
          if (!icon) {
            this.makeToast("danger", "Error", `Select an icon to continue!`);
            return;
          }
          this.creatingIssue = true;
          axios
            .post(
              `${process.env.VUE_APP_SERVER_URL}/api/issue/create`,
              {
                name: this.name,
                icon: this.icon,
              },
              {
                headers: {
                  Authorization: `Bearer ${getUserToken()}`,
                },
              }
            )
            .then(async (response) => {
              this.creatingIssue = false;
              this.makeToast(
                "success",
                "Success",
                `Issue Created successfully!`
              );
              await this.delay(1000);
              window.location.reload();
            })
            .catch((error) => {
              console.log(error.response);
              this.creatingIssue = false;

              if (
                error.response &&
                error.response.data &&
                error.response.data.errors
              ) {
                this.$displayErrors(error.response.data.errors);
                return;
              }

              this.makeToast(
                "danger",
                "Error",
                `Error occured while creating Issue!`
              );
            });
        }
      });
    },
    getIssues() {
      let queryParams = [];
      queryParams.push(`limit=${this.perPage}`);
      queryParams.push(`page=${this.page}`);
      if (this.search) {
        queryParams.push(`name=${this.search}`);
      }

      queryParams = queryParams.join("&");

      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/api/issue/get?${queryParams}`, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          this.issues = response.data.issues;
          this.rows = response.data.totalCount;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response.data);
          this.loading = false;
        });
    },
    handleLogoSelect(media) {
      if (media.status) {
        this.icon = media.media.src;
      }
    },
  },
  mounted() {
    this.getIssues();
  },
};
</script>

<style scoped>
.media-selecion {
  display: flex;
}

.media-selecion img {
  max-width: 200px;
}
</style>
